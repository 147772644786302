// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"

import machine_washable_icon from "../images/integrated-technologies/Icons/Machine_Washable.svg"
import machine_washable_icon_white from "../images/integrated-technologies/Icons/Machine_Washable_white.svg"
import scale_icon from "../images/integrated-technologies/Icons/scale.svg"
import scale_icon_white from "../images/integrated-technologies/Icons/scale_white.svg"
import seemles_icon_white from "../images/integrated-technologies/Icons/seemles_white.svg"
import seemles_icon from "../images/integrated-technologies/Icons/seemles.svg"
import testing_icon from "../images/integrated-technologies/Icons/testing_icon.svg"
import testing_icon_white from "../images/integrated-technologies/Icons/testingicon_white.svg"
import Platform_Feat_slider from "../components/common/platform-feat-slider"

const IntegratedTechnologies = ({ data }) => {
  const images = {
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),

    mint_texture: convertToBgImage(getImage(data.mint_texture)),

    //home page images

    banner: convertToBgImage(getImage(data.home1)),
    data: convertToBgImage(getImage(data.home2)),
    feedback: convertToBgImage(getImage(data.home3)),
    flex_battery_img: convertToBgImage(getImage(data.home4)),
    sensing_img: convertToBgImage(getImage(data.home5)),
    thermal_img: convertToBgImage(getImage(data.home6)),
    touch_img: convertToBgImage(getImage(data.home7)),
    visibility_img: convertToBgImage(getImage(data.home8)),
    circuitry_img: convertToBgImage(getImage(data.home9)),
    feedback_img: convertToBgImage(getImage(data.feedback)),

    //platform application images

    haptic_img: convertToBgImage(getImage(data.plat_app1)),
    motion_bra_img: convertToBgImage(getImage(data.plat_app2)),
    sense_pant_img: convertToBgImage(getImage(data.plat_app3)),
  }

  const [activePlatform, setActivePlatform] = useState(0)

  const platform_feat = [
    {
      icon: testing_icon,
      icon_white: testing_icon_white,
      title: "Tested",
      desc: "Comprehensive testing is undertaken to ensure complete functionality and safety of electronics.",
    },
    {
      icon: machine_washable_icon,
      icon_white: machine_washable_icon_white,
      title: "Washable",
      desc: "Electronically designed to be machine washable up to 50 wash cycles.",
    },
    {
      icon: seemles_icon,
      icon_white: seemles_icon_white,
      title: "Seamless",
      desc: "Mastery of seamless integration of electronics to ensure comfort and aesthetic continuity.",
    },
    {
      icon: scale_icon,
      icon_white: scale_icon_white,
      title: "Scale",
      desc: "Technologies and derived products designed and developed to be bulk compatible.",
    },
  ]

  const slider = [
    {
      title: "Proprietary ideation and development",
      content:
        "Our unique integrated approach to design and functionality reflects a commitment to sustainability and engineered craftsmanship.",
      image: images.slider_1,
    },
    {
      title: "Integrating technology",
      content:
        "We are pioneers in engineering soft goods with integrated structure and embedded electronic components.",
      image: images.slider_2,
    },
    {
      title: "Industry standards",
      content:
        "We operate ISO 9001, 14001 and 13485, OHSAS 18001 and LEED-compliant manufacturing facilities globally.",
      image: images.slider_3,
    },
    {
      title: "Manufacturing",
      content:
        "Our innovative manufacturing solutions merge craftsmanship with the replicability and scale required for consumer electronics.",
      image: images.slider_1,
    },
  ]

  const [active, setActive] = useState(0)

  const platform = [
    {
      title: "Health and Wellness",
      sub_title: "Respiration Sensing Bra",
      content:
        "An ECG sensor integrated into a sports bra that measures heart rate, respiration, calories, and workout effort during high-intensity fitness workouts.",
      image: "../../images/brand/symbol-mint.png",
      cover: images.motion_bra_img,
    },
    {
      title: "Connected Clothing",
      sub_title: "Motion Sensing Gacarment",
      content:
        "An IMU sensor integrated into the waistband of running shorts to measure running-related biomechanics, such as pelvic rotation, speed, bounce rate, etc.",
      image: "../../images/brand/symbol-mint.png",
      cover: images.sense_pant_img,
    },
    {
      title: "Performance",
      sub_title: "Haptic Feedback Pant",
      content:
        "Haptic motors integrated seamlessly into yoga pants thattrack form and automatically zonally vibrate if incorrect form or technique was detected.",
      image: "../../images/brand/symbol-mint.png",
      cover: images.haptic_img,
    },
  ]

  return (
    <Layout>
      <Seo title="Integrated Technologies" />
      <section>
        <BackgroundImage
          {...images.dusk_texure}
          className="h-screen bg-dusk overflow-hidden"
        >
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center ">
              <Fade bottom cascade>
                <div className="flex flex-col w-full z-10 sm:mt-0 -mt-52">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 max-w-2xl text-white">
                    Intelligent
                    <div>Textile Solutions</div>
                  </div>
                  <div className=" sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    Redefining what’s possible through
                    <div>power and integration.</div>
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div className=" absolute right-0 bottom-0">
                  <StaticImage
                    src="../images/integrated-technologies/hero.png"
                    alt="Softmatter"
                    placeholder="blurred"
                    width={820}
                  />
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-20">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-mint mb-4 uppercase text-center ">
                    NEXT-TO-SKIN BREAKTHROUGHS
                  </div>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Technology Integration
                  </div>
                  {/* <div className="text-2xl font-light text-center max-w-5xl leading-snug text-dusk">
                    Our groundbreaking textile-integrated illumination
                    technology that is revolutionizing the future of apparel,
                    setting new standards for safety, visibility, and product
                    differentiation. The platform offers more than 450 feet of
                    visibility in low light conditions with over 8 hours of
                    continuous illumination.
                  </div> */}
                  <div className="text-2xl font-light text-center max-w-5xl leading-snug mt-4 text-dusk">
                    Through extensive R&D we have cracked the code on the
                    seamless integration of electronics into soft goods. Our own
                    solutions range from biopotential and biomechanical sensing,
                    to active heating and lighting, to the integration of
                    third-party technologies into textiles.
                  </div>
                </div>
              </Fade>
              <div className="mt-24">
                <Fade bottom>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Platform Features
                  </div>
                </Fade>
              </div>
            </div>
            <div className="mt-8">
              <Platform_Feat_slider
                platform_feat_slider_array={platform_feat}
                mint
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-dusk overflow-hidden">
          <div className=" py-24">
            <Fade bottom cascade>
              <div className="text-white">
                <div className="text-center text-2xl font-firs-thin text-white mb-4">
                  PLATFORMS
                </div>
                <div className="text-center text-4xl lg:text-6xl mb-4">
                  Integrated Technologies
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  A collection of intelligent sensors, actuators and next
                  generation powering solutions integrated seamlessly into
                  textiles.
                </div>
              </div>
            </Fade>
            <div className="mt-32 overflow-hidden">
              <div className="flex lg:flex-row flex-col">
                <div className="lg-full g:w-1/2">
                  <Fade right>
                    <div className="w-full block lg:hidden">
                      <BackgroundImage
                        {...images.visibility_img}
                        className="py-44 sm:py-60"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Visibility
                      </div>
                      <div className="text-base max-w-xl">
                        A next-to-skin washable active lighting system, that
                        provides on-demand illumination up to 450ft to keep you
                        visible and safe. By maximizing power utilization and
                        reducing the number of light sources needed to cover a
                        large area, our solution offers even and soft
                        illumination that is genuinely versatile.
                      </div>
                      <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                        Learn More
                      </button>
                    </div>
                  </Fade>
                </div>
                <div className="w-full lg:w-1/2 hidden lg:block">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.visibility_img}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.thermal_img}
                        className="h-full bg-cover py-40 lg:py-60"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Thermal Regulation
                      </div>
                      <div className="text-base max-w-xl">
                        Active heating through the seamless integration of
                        wiring or conductive yarn into a fabric form-factor.
                        This intelligent textile-based thermal regulation
                        technology supports use cases ranging from microclimate
                        regulation to pain management, and leverages the
                        strategic placement of heating zones for optimal comfort
                        and functionality.
                      </div>
                      <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                        Learn More
                      </button>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2 block lg:hidden">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.sensing_img}
                        className="h-full bg-cover lg:py-0 py-44"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Sensing
                      </div>
                      <div className="text-base max-w-xl">
                        Through science and engineering, we have developed
                        washable miniature sensors that can be integrated into
                        apparel and soft goods. Our sensors can measure both
                        Biopotential and Biometrics – we have created and
                        integrated ECG, EMG, IMU, and temperature sensors into a
                        variety of products. Our range of technologies provides
                        design versatility for applications that are only
                        limited by your imagination.
                      </div>

                      <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                        Learn More
                      </button>
                    </div>
                  </Fade>
                </div>
                <div className="w-full lg:w-1/2 hidden lg:block">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.sensing_img}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2 ">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.touch_img}
                        className="py-44 sm:py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Touch Textiles
                      </div>
                      <div className="text-base max-w-xl">
                        A touch-sensitive smart textile solution that can be
                        seamlessly integrated into everyday electronics,
                        allowing them to do more. Limited only by your
                        imagination, this multi-functional technology can
                        replace the need for traditional plastic interfaces &
                        usher in a new era of connected and interactive soft
                        goods.
                      </div>
                      <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                        Learn More
                      </button>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2 block lg:hidden">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.data}
                        className="h-full bg-cover lg:py-0 py-44"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Data Transfer
                      </div>
                      <div className="text-base max-w-xl">
                        Fabric-based high-speed data transmission with limited
                        interference and low resistance, at speeds of over
                        4gbps. Our flexible conductors are stretchable,
                        water-resistant, and widely configurable, allowing for
                        different mechanical profiles and bandwidth
                        requirements. Ideal for use in next to skin applications
                        ranging from virtual reality to performance monitoring.
                      </div>

                      <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                        Learn More
                      </button>
                    </div>
                  </Fade>
                </div>
                <div className="w-full lg:w-1/2 hidden lg:block">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.data}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.feedback_img}
                        className="py-44 sm:py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Feedback
                      </div>
                      <div className="text-base max-w-xl">
                        Embrace a responsive future, with the integration of
                        electronic actuators into soft good solutions. We
                        believe that feedback technology is widely applicable in
                        wearables, from haptics for notification purposes, to
                        TENS and EMS for chronic pain management. Our feedback
                        systems are completely discrete and will not obstruct
                        your normal range of motion
                      </div>
                      <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                        Learn More
                      </button>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2 block lg:hidden">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.flex_battery_img}
                        className="py-44 sm:py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Flexible Battery
                      </div>
                      <div className="text-base max-w-xl">
                        Adaptable battery for a multitude of wearable uses.
                        Flexible, washable, semi-solid electrolyte construction
                        that serves as the industry’s solution to these growing
                        design needs. Greater freedom for designers results in
                        better products for users, and customization.
                      </div>

                      <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                        Learn More
                      </button>
                    </div>
                  </Fade>
                </div>
                <div className="w-full lg:w-1/2 hidden lg:block">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.flex_battery_img}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>

              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.circuitry_img}
                        className="py-44 sm:py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Textile Circuitry
                      </div>
                      <div className="text-base max-w-xl">
                        Integrating functionality into your textile product in
                        an invisible and seamless manner.
                      </div>
                      <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                        Learn More
                      </button>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="py-20 bg-chalk ">
          <div className="container mx-auto">
            <Fade top>
              <div className="text-4xl font-light text-center text-dusk mb-4">
                Platform Application
              </div>
            </Fade>
            <Fade bottom>
              <div className="text-2xl font-light text-center mx-auto text-dusk max-w-4xl leading-snug">
                Integration of technology into textiles to create robust,
                scalable,
                <div>and functional solutions</div>
              </div>
            </Fade>
            <div className="mt-14">
              <div className="flex items-center justify-center lg:gap-32 sm:gap-5">
                {platform.map((item, index) => {
                  return (
                    <Fade bottom cascade key={index}>
                      <div>
                        <div
                          className={
                            active == index
                              ? "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-5 border-dusk duration-200"
                              : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-5 border-transparent duration-200"
                          }
                          onClick={() => setActive(index)}
                        >
                          {item.title}
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </div>
              <div className="mt-8">
                <Fade bottom>
                  <div className="flex lg:flex-row flex-col sm:text-left text-center">
                    <BackgroundImage
                      {...platform[active].cover}
                      className="w-full lg:w-2/5 py-44"
                    ></BackgroundImage>
                    <div className="w-full lg:w-3/5 bg-dusk p-14 flex flex-col justify-center">
                      <div className="text-2xl font-light text-white">
                        {platform[active].sub_title}
                      </div>
                      <div className="text-xl font-light mt-4 text-white max-w-2xl">
                        {platform[active].content}
                      </div>
                      <div className="text-xl font-light text-white max-w-2xl">
                        {platform[active].content2}
                      </div>
                      <div className="text-xl font-light text-white max-w-2xl">
                        {platform[active].content3}
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="lg:w-1/2 w-full">
            <BackgroundImage
              {...images.mint_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    className="lg:w-auto w-20"
                    width={180}
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="lg:w-1/2 w-full">
            <Fade bottom cascade>
              <div className="bg-chalk py-56 text-dusk px-5">
                <div className="text-center text-5xl sm:text-6xl mb-4 px-1">
                  How to Get Started
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-mint  hover:bg-mint/90 duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query IntegratedTechnologiesImages {
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    home1: file(relativePath: { eq: "integrated-technologies/banner.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home2: file(relativePath: { eq: "integrated-technologies/Data.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home3: file(relativePath: { eq: "integrated-technologies/feedback.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home4: file(
      relativePath: { eq: "integrated-technologies/Flexible_battery.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home5: file(relativePath: { eq: "integrated-technologies/Sensing.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home6: file(relativePath: { eq: "integrated-technologies/Thermal.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home7: file(relativePath: { eq: "integrated-technologies/Touch.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home8: file(
      relativePath: { eq: "integrated-technologies/Visibility.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home9: file(
      relativePath: { eq: "integrated-technologies/Textile_Circ.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    feedback: file(
      relativePath: { eq: "integrated-technologies/feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    plat_app1: file(
      relativePath: {
        eq: "integrated-technologies/Platform-Applications/Haptic_Pants.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    plat_app2: file(
      relativePath: {
        eq: "integrated-technologies/Platform-Applications/Motion_sensing_bra.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    plat_app3: file(
      relativePath: {
        eq: "integrated-technologies/Platform-Applications/Sensing_Pants.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default IntegratedTechnologies
